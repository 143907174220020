import { Card, CardContent, CardHeader, TextField, FormControlLabel } from '@mui/material'
import { CenteredDiv, CustomSelect, getResourceUrl, CustomCheckbox, useWtxLocalization } from '@wavetronix/common-components'
import { MuiTelInput } from 'mui-tel-input'
import { useEffect, useState } from 'react'
import { env } from '../index.js'

const DEFAULT_USER = {
  name: '',
  email: '',
  phoneNumber: '',
  job: '',
  office: 'No Office',
  cell: ''
}
const classes = {
  card: {
    minWidth: 100,
    maxWidth: 900,
    margin: '24px'
  },
  fieldStyle: {
    width: '100%',
    marginTop: '15px'
  },
  name: {
    fontSize: '18px',
    fontFamily: 'Klavika, Verdana, sans-serif'
  },
  empInfo: {
    display: 'block',
    fontFamily: 'Proxima Nova, sans-serif',
    fontSize: '16px',
    color: '#888888',
    letterSpacing: '1px'
  }
}
const officeOptions = [
  'Springville Headquarters',
  'Florida Office',
  'Tennessee Office',
  'New York/New England Office',
  'Georgia Office',
  'UK Office',
  'France Office',
  'China Office',
  'Great Lakes Office',
  'Heartland Office',
  'Mid-Atlantic Office',
  'No Office',
  'Other'
]
export default function EmailSignGenerator(props) {
  const [user, setUser] = useState(DEFAULT_USER)
  const [phone, setPhone] = useState('(801) 734-7200')
  const [customPhone, setCustomPhone] = useState('')
  const [includeConf, setIncludeConf] = useState(false)
  const localizedStrings = useWtxLocalization()
  useEffect(() => {
    if (user.office) {
      if (user.office === 'Springville Headquarters') {
        setPhone('(801) 734-7200')
      } else if (user.office === 'Florida Office') {
        setPhone('(407) 366-5144')
      } else if (user.office === 'Tennessee Office') {
        setPhone('(629) 888-0777')
      } else if (user.office === 'UK Office') {
        setPhone('+44 (0) 1293 775 487')
      } else if (user.office === 'New York/New England Office') {
        setPhone('(518) 330-7574')
      } else if (user.office === 'France Office') {
        setPhone('+33 (0)5 47 51 81 20')
      } else if (user.office === 'China Office') {
        setPhone('(86) 10-6494-3046')
      } else if (user.office === 'Georgia Office') {
        setPhone('(678) 670-6227')
      } else if (user.office === 'Great Lakes Office') {
        setPhone('(616) 446-0054')
      } else if (user.office === 'Heartland Office') {
        setPhone('(405) 441-4890')
      } else if (user.office === 'Mid-Atlantic Office') {
        setPhone('(804) 357-0513')
      } else if (user.office === 'No Office') {
        setPhone('')
      } else if (user.office === 'Other') {
      }
    }
  }, [user.office])
  return (
    <CenteredDiv>
      <Card sx={classes.card}>
        <CardHeader title={localizedStrings.createSigPrompt} />
        <CardContent>
          <TextField
            style={classes.fieldStyle}
            label='Name'
            size='small'
            value={user.name}
            variant='outlined'
            onChange={e => setUser({ ...user, name: e.target.value })}
          />
          <TextField
            style={classes.fieldStyle}
            label='Job Title'
            size='small'
            value={user.job}
            variant='outlined'
            onChange={e => setUser({ ...user, job: e.target.value })}
          />
          <TextField
            style={classes.fieldStyle}
            label='Work Email'
            size='small'
            value={user.email}
            variant='outlined'
            onChange={e => setUser({ ...user, email: e.target.value })}
          />
          <MuiTelInput
            defaultCountry='US'
            label='Work Mobile/Desk Phone Number (optional)'
            value={user.cell}
            onChange={e => setUser({ ...user, cell: e })}
            style={classes.fieldStyle}
            size='small'
          />
          <CustomSelect
            style={classes.fieldStyle}
            label='Office'
            value={user.office}
            onChange={e => setUser({ ...user, office: e.target.value })}
            options={officeOptions}
          />
          {user.office === 'Other' ? (
            <MuiTelInput
              label='Office Number'
              defaultCountry='US'
              value={customPhone}
              onChange={setCustomPhone}
              style={classes.fieldStyle}
              size='small'
            />
          ) : null}
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={includeConf}
                onClick={e => {
                  setIncludeConf(e.target.checked)
                }}
              />
            }
            label={<p>Include Confidentiality Statement</p>}
          />

          <div>
            <p>
              Please copy and paste this into your email signature settings. If needed, copy the legal part at the bottom as well.
              For questions or problems please email webmaster@wavetronix.com.
            </p>
            <div>
              <hr />

              <div style={{ display: 'inline-block' }}>
                <a href='https://www.wavetronix.com/' target='_blank' rel='noreferrer'>
                  <img
                    src={`${getResourceUrl(env.runMode)}/images/logos/wtx_logo_neutral_gray.png`}
                    alt='Wavetronix'
                    style={{ border: 'none', alignContent: 'left' }}
                    height='40'
                    width='150'
                  />
                </a>
              </div>
              <div style={{ display: 'inlineBlock', verticalAlign: 'top' }}>
                <div style={{ display: 'block', paddingTop: '3px' }}>
                  <span style={classes.name}>{user.name}</span>
                </div>
                <div style={classes.empInfo}>
                  <span>{user.job}</span>
                </div>
                {user.cell !== '' ? (
                  <div style={classes.empInfo}>
                    <span>P: {user.cell}</span>
                  </div>
                ) : null}
                {user.office === 'Other' ? (
                  <div style={classes.empInfo}>
                    <span>O: {customPhone}</span>
                  </div>
                ) : user.office === 'No Office' ? null : (
                  <div style={classes.empInfo}>
                    <span>O: {phone}</span>
                  </div>
                )}
                <div style={classes.empInfo}>
                  <span>{user.email}</span>
                </div>
              </div>
              <hr style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '0px' }} />
              <span
                style={{
                  ...classes.empInfo,
                  fontSize: '10px'
                }}>
                {includeConf === true ? (
                  <div>
                    This email and any files transmitted with it are confidential and intended solely for the use of the
                    individual or entity to whom it is addressed. If you received this email in error, please notify the system
                    manager.
                  </div>
                ) : null}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
    </CenteredDiv>
  )
}
